<template>
  <div>
    <new-page>
      <div v-wechat-title="$route.meta.title"></div>
      <div class="main">
        <div
          v-for="item in allToolsCategory"
          :key="item.id"
          class="tools_categroy"
        >
          <!-- 标题 -->
          <div class="tools_categroy_top">
            <div class="category_name_left"></div>
            <span class="category_name">{{ item.tools_category_name }}</span>
          </div>
          <!-- 分割线 -->
          <hr class="category_hr" />
          <!-- 工具 -->
          <div class="tools">
            <div
              class="tools_card"
              v-for="tools in filterTools(item.id)"
              :key="tools.id"
            >
              <a
                class="tools_card_item"
                :href="tools.tools_url"
                target="_blank"
              >
                <div class="tools_card_top">
                  <div class="tools_cover_picture">
                    <img
                      :src="photoUrl(tools.tools_cover_picture)"
                      class="cover_picture"
                    />
                  </div>
                  <span class="tools_name">{{ tools.tools_name }}</span>
                </div>
                <div class="tools_introduce">
                  {{ tools.tools_introduce }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </new-page>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import NewPage from '../components/NewPage'
import { SELECTALLTOOLSCATEGORY, SELECTAllTOOLS } from '../api/api'
export default {
  components: { NewPage },
  setup() {
    onMounted(() => {
      selectAllToolsCategory()
      selectAllTools()
    })
    // 所有工具分类信息
    const allToolsCategory = ref([])
    // 查询所有工具分类
    const selectAllToolsCategory = () => {
      SELECTALLTOOLSCATEGORY().then((response) => {
        if (response.code === 0) {
          allToolsCategory.value = response.data
        }
      })
    }
    // 处理图片展示路径
    const photoUrl = (url) => {
      return (
        'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/' +
        JSON.parse(url)[0]
      )
    }
    // 所有工具信息
    const allTools = ref([])
    // 查询所有工具
    const selectAllTools = () => {
      SELECTAllTOOLS().then((response) => {
        if (response.code === 0) {
          allTools.value = response.data
        }
      })
    }
    // 过滤工具信息
    const filterTools = (tools_category_id) => {
      return allTools.value
        .filter((item) => item.tools_category_id === tools_category_id)
        .reverse()
    }
    return {
      allToolsCategory,
      photoUrl,
      allTools,
      filterTools
    }
  }
}
</script>

<style>
.el-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: auto;
}
</style>
<style scoped>
.main {
  padding: 24px;
  background-color: #ffffff;
  margin-top: 80px;
  border-radius: 15px;
}

/* 分类名称 */
.tools_categroy {
  margin-bottom: 40px;
}

.tools_categroy_top {
  display: flex;
  padding: 8px;
}

.category_name_left {
  width: 6px;
  height: 26px;
  background-color: var(--subject-color);
}

.category_name {
  font-weight: bold;
  font-size: 20px;
  margin-left: 8px;
  color: #414240;
}

.category_hr {
  margin: 4px 8px;
  background-color: var(--subject-color);
  border: none;
  height: 1px;
}

/* 工具卡片 */
.tools {
  display: flex;
  flex-wrap: wrap;
}

.tools_card {
  position: relative;
  margin: 16px 8px;
}

.tools_card_item {
  height: 139px;
  width: 208px;
  border: 1px solid #eaeaea;
  padding: 8px;
  display: block;
  position: relative;
  transform: translate(0);
  transition: transform 0.26s, box-shadow 0.26s;
  z-index: 3;
  background: #fff;
}

.tools_card_item:hover {
  transform: translateY(10px);
  box-shadow: 0 4px 8px 0 rgba(7, 17, 27, 0.1);
}

.tools_card::before {
  content: '';
  background-color: #fff;
  height: 3px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 0.25rem;
  right: 0.5rem;
  bottom: -4px;
  border: 1px solid #eaeaea;
  border-top: none;
  box-shadow: 0 4px 8px 0 rgb(7 17 27 / 10%);
  z-index: 2;
  background-color: #fff;
}

.tools_card::after {
  content: '';
  background-color: #fff;
  height: 8px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: -8px;
  left: 0.5rem;
  right: 0.25rem;
  border: 1px solid #eaeaea;
  border-top: none;
  box-shadow: 0 4px 8px 0 rgb(7 17 27 / 10%);
  z-index: 1;
  background-color: #fff;
}

.tools_card_top {
  display: flex;
  align-items: center;
}

.cover_picture {
  height: 38px;
  width: 38px;
}

.tools_name {
  margin-left: 8px;
}

.tools_introduce {
  font-size: 14px;
  color: #999999;
  margin-top: 16px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

@media screen and (max-width: 560px) {
  .tools_card_item {
    width: calc(100vw - 96px);
  }
}
</style>
<!-- <style src="../css/tools.css" scoped>
</style> -->
